@tailwind base;
@tailwind components;
@tailwind utilities;

header.dropzone {
  padding: 30px;
  border: 3px dashed white;
}

.blurblack {
  background: #0000004d;
  backdrop-filter: blur(6px);
}

.Dropdown-placeholder,
.Dropdown-option {
  font-weight: 400;
}


.back {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10px;
}

input:checked + svg {
  display: block;
}

a.delete {
  background: #000000d9;
  border-radius: 4px;
  width: 30px;
  margin: 2px;
  height: 30px;
  position: fixed;
  display: inline-block;
}

.form-upload {
  text-align: center;
}

body {
  height: 100vh;
  width: 100vw;
}

.back {
  height: 100%;
  width: 100%;

  /* example padding, font-size, background, etc */
  padding: 10px;
  font-size: 20px;
  background-color: lightskyblue;
}
