.App {
  /*text-align: center;*/
}

.full-page {
  background: url(https://images.unsplash.com/photo-1505784759936-f88148534612?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1953&q=80)
    no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  overflow: scroll;
  padding-top: 50px;
}

#screen {
  height: 100vh;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.Node-logo {
  height: 30vmin;
  pointer-events: none;
}

.logo-box {
  padding-right: 4rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

body {
  background-color: #e4e7eb;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 100s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #003948;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel .slide img {
  height: 100% !important;
  display: block !important;
  width: auto !important;
  margin: 0 auto !important;
}

.caritem {
}

.carouser_sizer {
}

.gallery-thumbnails--toggle {
  display: none !important;
}

p.photo-subcaption {
  display: block;
  float: right;
  font-size: 1rem !important;
  color: white !important;
  font-weight: 500 !important;
}

h3.photo-caption {
  display: block;
  float: left;
}

p.photo-subcaption {
}

.public {
  overflow-x: hidden;
  padding: 20px;
}
.sub {
  margin-top: -30px;
  display: block;
  background: #ffffff4d;
  color: black;
  padding: 3px;
  border-radius: 4px;
  position: relative;
}

.gallery-top {
  display: block !important;
}

.gallery {
  display: block !important;
}

@media (min-width: 744px) {
  .gallery .gallery-photo {
    padding-bottom: 82% !important;
  }
  .gallery-figcaption--info {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
